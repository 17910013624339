import axios from './shein-axios/index'
import {
  cookieFix,
  fixBffChallengeContent,
  apiSignatureRequest,
  apiSignatureResponse,
  signatureFeedBack,
  asyncCheckLogin,
  forceLogin,
  circuitBreakerTip,
  loading,
  apiLog,
  preRequestApiMark,
  traceMonitor,
  commonHeader,
  ipv6,
  dataAggregation,
  networkError,
  aiDataRequestInterceptor,
} from './interceptors'
import cmcClientService from '@shein-aidc/basis-end-config'
import { setAxiosHeader } from 'public/src/services/personalzed/index'

const schttp = (() => {
    // 谨防 SSR 改写 node axios
    if (typeof window === 'undefined') return axios
    const instance = axios.create({
      baseURL: gbCommonInfo.langPath,
      headers: {
        common: {
          'x-csrf-token': gbCommonInfo.csrf_token,
          'x-requested-with': 'XMLHttpRequest'
        }
      },
      params: {
        _ver: '1.1.8',
        _lang: gbCommonInfo.appLanguage
      }
    })

    instance.updateXCsrfToken = async (csrfToken) => {
      if (!csrfToken) {
        const response = await instance({ url: '/api/common/csrf/update' }).catch(()=>{})
        if (!response.updateCs) return false
        csrfToken = response.updateCs
      }
      // eslint-disable-next-line require-atomic-updates
      instance.defaults.headers.common['x-csrf-token'] = csrfToken
      gbCommonInfo.csrf_token = csrfToken
      return csrfToken
    }

    // ========== set your interceptors here ==========
    instance.interceptors.request.use(cookieFix)
    instance.interceptors.request.use(loading.request)
    instance.interceptors.request.use(preRequestApiMark.request)
    instance.interceptors.request.use(apiSignatureRequest) // 参数签名，所有相关参数的拦截器都应该在它之前执行
    instance.interceptors.request.use(aiDataRequestInterceptor)
    instance.interceptors.request.use(signatureFeedBack.hold)
    instance.interceptors.request.use(traceMonitor.startRootSpan)
    instance.interceptors.request.use(commonHeader)
    instance.interceptors.request.use(setAxiosHeader)
    instance.interceptors.request.use(dataAggregation)
    // -- request   ↑
    // -- response  ↓
    instance.interceptors.response.use(fixBffChallengeContent)
    instance.interceptors.response.use(apiLog.success, apiLog.error)
    instance.interceptors.response.use(res=> res, networkError)
    instance.interceptors.response.use(apiSignatureResponse)
    instance.interceptors.response.use(signatureFeedBack.replay(instance))
    instance.interceptors.response.use(loading.response, loading.response)
    instance.interceptors.response.use(asyncCheckLogin(instance))
    instance.interceptors.response.use(forceLogin)
    instance.interceptors.response.use(circuitBreakerTip)
    instance.interceptors.response.use(traceMonitor.success, traceMonitor.error)
    instance.interceptors.response.use(preRequestApiMark.response, preRequestApiMark.response)
    instance.interceptors.response.use(ipv6)
    instance.interceptors.response.use((response) => {
      cmcClientService?.httpIntercept?.(response.heanders)
      return response
    })
  
    // ...
    // ========== END ==========
    instance.interceptors.response.use(
      response => response.config?.schttp?.getResponse || response.config?.__fromBSLibs__ ? response : response.data,
      response => { throw response.config?.schttp?.getResponse || !response.request ? response : response.request }
    )

    window.schttp = instance
    return instance
})()

/**
 * ========== best practice ==========
 * import schttp from 'public/src/services/schttp'
 * 只使用对象形式请求, 以便未来技术替换
 * https://axios-http.com/zh/docs/req_config
 *
 * schttp({
 *   // method: 'GET', // by default
 *   url: '/somePath/someApi',
 *   params: { foo: 'bar' }
 * })
 *
 * schttp({
 *   method: 'POST',
 *   url: '/somePath/someApi',
 *   data: { foo: 'bar' }
 * })
 */

export default schttp
